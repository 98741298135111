<template>
  <b-sidebar
    id="edit-customer-sidebar"
    :visible="isEditCustomerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-customer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Customer Info
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateCustomerInfo)"
        >
          <b-form-group
            label="First Name"
            label-for="firstName"
          >
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="model"
                v-model="newCustomerData.firstName"
                placeholder="Enter first name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Last Name"
            label-for="lastName"
          >
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="model"
                v-model="newCustomerData.lastName"
                placeholder="Enter last name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="email"
            >
              <b-form-input
                id="customer-email"
                v-model="newCustomerData.email"
                placeholder="Enter Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Phone Number"
            label-for="customer-phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone Number"
              rules="phoneNumber"
            >
              <b-form-input
                id="customer-phone"
                v-model="newCustomerData.phoneNumber"
                placeholder="Enter Phone Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Postal Code -->
          <b-form-group
            label="Postal Code"
            label-for="postalCode"
          >
            <validation-provider
              #default="{ errors }"
              name="Postal Code"
            >
              <b-form-input
                id="postalCode"
                v-model="newCustomerData.postalCode"
                placeholder="Enter Postal Code"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Street Address -->
          <b-form-group
            label="Street Address"
            label-for="streetAddress"
          >
            <validation-provider
              #default="{ errors }"
              name="Street Address"
            >
              <b-form-input
                id="streetAddress"
                v-model="newCustomerData.streetAddress"
                placeholder="Enter Street Address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="company"
          >
            <b-form-checkbox
              v-model="newCustomerData.company"
              class="mt-50 custom-control-warning"
              :value="true"
              :unchecked-value="false"
            >
              Business Customer
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="newCustomerData.company"
            label="Business Name"
            label-for="companyName"
          >
            <validation-provider
              #default="{ errors }"
              name="Business Name"
            >
              <b-form-input
                id="model"
                v-model="newCustomerData.companyName"
                placeholder="Enter company name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="newCustomerData.company"
            label="Business VAT No."
            label-for="companyVatNumber"
          >
            <validation-provider
              #default="{ errors }"
              name="Business VAT No."
            >
              <b-form-input
                id="model"
                v-model="newCustomerData.companyVatNumber"
                placeholder="Enter company VAT No."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div v-if="!!newCustomerData">
            <b-card
              no-body
              class="border-primary"
            >
              <b-card-header class="d-flex justify-content-between pt-75 pb-25 px-1">
                <h3 class="mb-0">
                  {{ newCustomerData.firstName ? newCustomerData.firstName : '' }}
                  {{ newCustomerData.lastName ? newCustomerData.lastName : '' }}
                </h3>
                <b-badge
                  v-if="!!newCustomerData.company"
                  variant="light-primary"
                >
                  {{ newCustomerData.company ? 'Business' : "Private" }}
                </b-badge>
              </b-card-header>
              <b-card-body class="px-1">
                <ul
                  class="list-unstyled my-1"
                >
                  <li>
                    <span class="align-middle">Email: {{ newCustomerData.email ? newCustomerData.email : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Phone: {{ newCustomerData.phoneNumber ? newCustomerData.phoneNumber : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Postal Code: {{ newCustomerData.postalCode ? newCustomerData.postalCode : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Address: {{ newCustomerData.streetAddress ? newCustomerData.streetAddress : 'N/A' }}</span>
                  </li>

                  <span v-if="newCustomerData.company === true">
                    <li class="mt-1 text-muted">
                      <span>Business Details</span>
                    </li>
                    <li>
                      <span class="align-middle">Business Name: {{ newCustomerData.companyName ? newCustomerData.companyName : 'N/A' }}</span>
                    </li>
                    <li>
                      <span class="align-middle">VAT Number: {{ newCustomerData.companyVatNumber ? newCustomerData.companyVatNumber : 'N/A' }}</span>
                    </li>
                  </span>

                </ul>
              </b-card-body>
            </b-card>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BBadge,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import phoneNumber from '@/libs/utils/validations/customValidations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormInput,
    flatPickr,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditCustomerSidebarActive',
    event: 'update:is-edit-customer-sidebar-active',
  },
  props: {
    isEditCustomerSidebarActive: {
      type: Boolean,
      required: true,
    },
    customerData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      phoneNumber,
      newCustomerData: this.$props.customerData ? this.$props.customerData : {},
      isLoading: false,
    }
  },
  methods: {
    handleUpdateCustomerInfo() {
      const customerData = {
        firstName: this.newCustomerData.firstName || '',
        lastName: this.newCustomerData.lastName || '',
        email: this.newCustomerData.email || null,
        phoneNumber: this.newCustomerData.phoneNumber || null,
        postalCode: this.newCustomerData.postalCode || null,
        streetAddress: this.newCustomerData.streetAddress || null,
        company: this.newCustomerData.company || false,
        companyName: this.newCustomerData.companyName || null,
        companyVatNumber: this.newCustomerData.companyVatNumber || null,
      }
      const customerId = this.customerData ? this.customerData.id : null

      if (customerId) return this.updateCustomer(customerId, customerData)
      return this.addCustomer(customerData)
    },
    updateCustomer(customerId, customerData) {
      this.isLoading = true
      store.dispatch('app-customer/updateCustomer', { customerId, customerData })
        .then(response => {
          this.newCustomerData = response.data
          this.$emit('updateCustomerInfo', this.newCustomerData)
          this.$emit('update:is-edit-customer-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Customer updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this customer.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          if (customerId) {
            store.dispatch('app-customer/fetchCustomer', { id: customerId })
              .then(response => {
                this.$emit('updateCustomerInfo', response.data)
                this.newCustomerData = response.data
              })
          }

          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$emit('update:is-edit-customer-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Customer update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this customer.',
            },
          })
          this.isLoading = false
        })
    },
    addCustomer(customerData) {
      this.isLoading = true
      store.dispatch('app-customer/createNewCustomer', customerData)
        .then(response => {
          this.$router.push({ name: 'customers-view', params: { id: response.data.id } })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Customer added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new customer.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error adding new customer',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this customer.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-customer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
